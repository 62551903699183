import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getHeaders } from "../../utility/helpers"

const emailAPI = createApi({
  reducerPath: "emailAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_COMMON_API_BASE_URL,
  }),
  endpoints: builder => ({
    checkEmail: builder.mutation({
      query: () => ({
        url: "/user/v1/email/check",
        method: "GET",
        headers: getHeaders()
      })
    }),
    sendEmailOTP: builder.mutation({
      query: ({ email }) => ({
        url: "/user/v1/email/update/",
        method: "PUT",
        params: { email },
        headers: getHeaders()
      })
    }),
    verifyEmail: builder.mutation({
      query: ({ email, otp }) => ({
        url: "/user/v1/email/confirm/",
        method: "POST",
        params: { email, otp },
        headers: getHeaders()
      })
    })
  })
})

export const {
  useCheckEmailMutation,
  useSendEmailOTPMutation,
  useVerifyEmailMutation
} = emailAPI

export default emailAPI
