/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { CircularProgress } from "@mui/material"
import { useSelector } from "react-redux";
import { useGetSkillMatrixMutation } from "../services/apis/chartAPI"
import { Chart as ChartJS, RadialLinearScale, PointElement } from "chart.js";
import { LineElement, Filler, Tooltip } from "chart.js";
import { Radar } from "react-chartjs-2"
import "./ChartPage.css"

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip
);

export default function ChartPage() {
  // api mutations
  const [getSkillMatrix] = useGetSkillMatrixMutation()
  // get theme code
  const theme = useSelector(states => states.common.theme)
  // dark mode flag
  const isDark = theme === "dark"
  // matrix data
  const [data, setData] = useState(null)
  // page loading state
  const [loading, setLoading] = useState(true)
  // load chart on mount
  useEffect(() => {
    getSkillMatrix().then(resp => {
      setTimeout(() => {
        setData(resp.data)
        setLoading(false)
      }, 400)
    })
  }, [])
  // chart page
  return (
    <div className="chart-page" data-loading={loading}>
      {
        loading && (
          <div className="chart-page loading">
            <CircularProgress />
          </div>
        )
      }
      {
        !loading && data && (
          <div className="chart-page-content">
            <Radar
              data={{
                labels: data.labels,
                datasets: [
                  {
                    label: " zScore ",
                    data: data.zScores,
                    fill: true,
                    backgroundColor: isDark ? "#FFF3" : "#0003",
                    borderColor: isDark ? "#FFFF" : "#000",
                    borderWidth: 1,
                    pointBackgroundColor: isDark ? "#000" : "#FFF",
                    pointBorderColor: isDark ? "#FFFF" : "#000",
                    pointHoverBackgroundColor: isDark ? "#FFFF" : "#000"
                  },
                ],
              }}

              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  r: {
                    grid: {
                      color: isDark ? "#FFF6" : "#0003",
                    },
                    ticks: {
                      font: {
                        weight: "bolder"
                      }
                    },
                    pointLabels: {
                      font: {
                        weight: "bolder"
                      },
                      color: isDark ? "#FFFA" : "#000A"
                    },
                    min: -3,
                    max: 3
                  }
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: context => {
                        const percentage = data.lowerScorePercents[context.dataIndex]
                        const value = context.raw;
                        return [
                          `Z-Score : ${parseFloat(value).toFixed(2)}`,
                          `Lower Percentage: ${parseFloat(percentage).toFixed(4)}`
                        ]
                      }
                    },
                    displayColors: false
                  }
                }
              }}
            />
          </div>
        )
      }
    </div>
  )
}
