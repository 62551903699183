import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getHeaders } from "../../utility/helpers"

const chartAPI = createApi({
  reducerPath: "chartAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MATRIX_API_BASE_URL,
  }),
  endpoints: builder => ({
    getSkillMatrix: builder.mutation({
      query: () => ({
        url: "/v1/skillmatrix",
        method: "GET",
        headers: getHeaders()
      }),
      transformResponse: response => ({
        labels: response.skillMatrix.map(item => item.name),
        zScores: response.skillMatrix.map(item => item.zScore),
        lowerScorePercents: response.skillMatrix.map(item => (
          item.lowerScorePercent
        ))
      })
    })
  })
})

export const {
  useGetSkillMatrixMutation
} = chartAPI

export default chartAPI
