import { getEnvironment } from "../utility/helpers"

export const analytics = {
  init() {
    if (getEnvironment() === "prod") {
      window.dataLayer = window.dataLayer || []
      window.gtag = function () {
        window.dataLayer.push(arguments)
      }
      window.gtag('js', new Date())
      window.gtag('config', 'G-EXAMPLE')
    }
  },
  logEvent(...args) {
    if (getEnvironment() === "prod") {
      // window.gtag("event", ...args)
    }
  }
}
